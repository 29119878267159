// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
import "./src/styles/breakpoints.less"
import "./src/styles/colors.less"
import "./src/styles/background-colors.less"
import "./src/styles/blog-typography.less"
import "./src/styles/flex.less"
import "./src/styles/blogcontent.less"
// require("prismjs/themes/prism-coy.css");
require("./src/styles/prism-blog.css");